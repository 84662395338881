(function(site, generic) {
  Drupal.behaviors.klarnaAccount = {
    data: {},
    element: {},
    config: Drupal.settings.klarna_installment,
    translations: {
      aboveLimit: site.translations.elc_common.klarna_available_for_orders || '::ELC_COMMON.KLARNA_AVAILABLE_FOR_ORDERS::',
      belowLimit: site.translations.elc_common.klarna_available_for_orders_above || '::ELC_COMMON.KLARNA_AVAILABLE_FOR_ORDERS_ABOVE::',
      installments: site.translations.elc_common.klarna_payments_of || '::ELC_COMMON.KLARNA_PAYMENTS_OF::',
      klarna: site.translations.elc_common.klarna || '::ELC_COMMON.KLARNA::',
      learnMore: site.translations.elc_general.learn_more || '::ELC_GENERAL.LEARN_MORE::',
      prepend: site.translations.elc_general.or || '::ELC_GENERAL.OR::',
      append: site.translations.elc_general.with || '::ELC_GENERAL.WITH::'
    },

    attach() {
      this.context = document.querySelector('klarna-placement');

      this.setCustomEvents();

      if (!this.context || !this.config) return;

      this.getData();
      this.setTranslations();
      this.setTemplate();
      this.getElements();
      this.setEvents();
      this.updateMessage();
    },

    getData() {
      this.data = {
        purchaseAmount: this.context.getAttribute('data-purchase-amount')
      };
    },

    setTranslations() {
      const { aboveLimit, belowLimit } = this.translations;
      const { minimum, maximum, currency, formatted_price } = this.config;
      var currency_min = formatted_price.replace('::currency::', currency).replace('::price::', minimum);
      var currency_max = formatted_price.replace('::currency::', currency).replace('::price::', maximum);
      this.translations.aboveLimit = aboveLimit.replace('::klarna_installment_minimum::', currency_min).replace('::klarna_installment_maximum::', currency_max);
      this.translations.belowLimit = belowLimit.replace('::klarna_installment_minimum::', currency_min);
    },

    setTemplate() {
      const { belowLimit, klarna, learnMore } = this.translations;

      this.context.innerHTML = `
        <span class="klarna-account__content">
          <span class="klarna-account__message js-klarna-account__message">${belowLimit}</span>
          <span class="klarna-account__logo">${klarna}</span>
          <a href="/klarna-information" class="klarna-account__learn-more js-klarna-account__learn-more" target="_blank">${learnMore}</a>
        </span>
      `;
    },

    getElements() {
      this.element = {
        klarnaMessage: this.context.querySelector('.js-klarna-account__message'),
        learnMore: this.context.querySelector('.js-klarna-account__learn-more')
      };
    },

    setEvents() {
      const { learnMore } = this.element;

      learnMore.addEventListener('click', event => {
        event.preventDefault();

        generic.overlay.launch({
          href: `${learnMore.getAttribute('href')} .klarna-information`,
          className: 'klarna-overlay',
          cssStyle: {
            height: 'auto',
            width: 660
          }
        });
      });
    },

    setCustomEvents() {
      document.addEventListener('klarnaAccount:attach', event => {
        this.attach(event.detail.context);
      });
    },

    updateMessage() {
      const { minimum, maximum, currency, formatted_price } = this.config;
      const { aboveLimit, installments, prepend, append } = this.translations;
      const payment = this.getPaymentAmount();
      let message = '';
      var currency_splitpayment = formatted_price.replace('::currency::', currency).replace('::price::', this.getSplitPayment());

      if (payment < minimum) return;

      if (payment > maximum) {
        message = aboveLimit;
        this.context.classList.add('klarna-account--over-limit');
      } else {
        message = installments.replace('::klarna_installment_payments::', currency_splitpayment);
        this.context.classList.remove('klarna-account--over-limit');
      }

      this.element.klarnaMessage.innerHTML = `
        <span class="klarna-account__prepend">${prepend} </span>
        ${message}
        <span class="klarna-account__append"> ${append}</span>
      `;
    },

    getPaymentAmount() {
      const { purchaseAmount } = this.data;
      return purchaseAmount / 100;
    },

    getSplitPayment() {
      const { purchaseAmount } = this.data;
      return (Math.floor((purchaseAmount / 3)) / 100).toFixed(2);
    }
  };
})(window.site || {}, window.generic || {});
